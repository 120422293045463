import { template as template_33d818d195764bbf9cd15a26392be678 } from "@ember/template-compiler";
const FKControlMenuContainer = template_33d818d195764bbf9cd15a26392be678(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
